/* eslint-disable */
import { GetTotalWattForAnHourFromAllEmeters, GetEmetersShelly3em } from './api-requests';

export const GetActiveHourWattData = async (startTime) => {
    let usedWatt = 0;
    //console.log('Starttid för aktiv rad:', startTime);
    // Get total from all three emeters at whole hour from neo4j
    let emeters = await GetTotalWattForAnHourFromAllEmeters(startTime);
    //console.log('emeters:', emeters);
    if (emeters[0].length > 0) {
        const emetersAndWatt = emeters[0].map((emeter) => {
            if (typeof emeter._fields[0].properties.total === 'object') {
                return {
                    'emeter': emeter._fields[0].properties.emeter,
                    'totalWatt': emeter._fields[0].properties.total.low
                }
            }
            else if (typeof emeter._fields[0].properties.total === 'number') {
                return {
                    'emeter': emeter._fields[0].properties.emeter,
                    'totalWatt': emeter._fields[0].properties.total
                }
            }
            else {
                return {
                    'emeter': emeter._fields[0].properties.emeter,
                    'totalWatt': 0
                }
            }
        });

        // Get total from all three emeters just now from shelly 3em
        const shelly3emEmeters = await GetEmetersShelly3em();
        //console.log('shelly3emEmeters:', shelly3emEmeters);
        //console.log('emetersAndWatt:', emetersAndWatt);
        shelly3emEmeters[0].forEach((emeter, idx) => {
            //console.log('emeter', emeter);
            // Count the difference between the three emeters and summarize the difference to one value
            //console.log('indx:',idx,' totalWatt', emeter.total - emetersAndWatt.filter(obj => obj.emeter === emeter.emeter)[0].totalWatt);
            let watt = emeter.total - emetersAndWatt.filter(obj => obj.emeter === emeter.emeter)[0].totalWatt;
            usedWatt += watt;
        });
    } else {
        usedWatt = 0;
    }
    // Return the value
    //console.log('usedWatt:', usedWatt);
    return usedWatt;
}