<!-- electricity-info.vue -->
<template>
    <div>
        <canvas ref="myCanvas" height="120"></canvas>
    </div>
</template>
  
<script>
export default {
    name: 'ElectricityGraphic',
    props: {
        price: {
            type: Number,
            required: true,
        },
        consumption: {
            type: Number,
            required: true,
        },
    },
    updated() {
        this.drawElectricityInfo();
    },
    mounted() {
        window.addEventListener('resize', this.drawElectricityInfo); // Adds lissener for resize
        this.drawElectricityInfo();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.drawElectricityInfo); // Removes the lissener for resize
    },
    methods: {
        maxPrice() {
            if (this.price) {
                if (this.price > 4) {
                    //console.log('över fyra');
                    return 4
                } else
                    //console.log('under fyra');
                return this.price
            } else return 0
        },
        drawElectricityInfo() {
            const canvas = this.$refs.myCanvas;
            const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            canvas.width = viewportWidth - 20;
            const ctx = canvas.getContext('2d');

            // Funktion för att rita rektangel med färgförändring från vänster till höger
            function drawGradientRect(leftColor, middleColor, rightColor, width, height) {
                const gradient = ctx.createLinearGradient(0, 0, width, 0);
                gradient.addColorStop(0, leftColor);
                gradient.addColorStop(0.2, middleColor);
                gradient.addColorStop(1, rightColor);
                ctx.fillStyle = gradient;
                ctx.fillRect(0, 30, width, height);
            }

            // Funktion för att rita en trekant
            function drawTriangle(color, centerX, centerY, width, height, pointingUp) {
                ctx.beginPath();
                ctx.moveTo(centerX, centerY);
                ctx.lineTo(centerX + width / 2, pointingUp ? centerY - height : centerY + height);
                ctx.lineTo(centerX - width / 2, pointingUp ? centerY - height : centerY + height);
                ctx.closePath();
                ctx.fillStyle = color;
                ctx.fill();
            }

            // Function for text
            function drawText(text, centerX, centerY) {
                ctx.font = '12px Bakbak One';
                ctx.fillStyle = 'black';
                ctx.textAlign = 'left';
                ctx.textBaseline = 'middle';
                ctx.fillText(text, centerX, centerY);
            }

            // Rensa canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // Rita trekant för elpris
            // const priceTriangleHeight = priceHeight / 6 * this.price;
            // drawTriangle('orange', canvas.width / 2, priceHeight / 2, 20, priceTriangleHeight, true);


            // Draw gredient rectangle
            drawGradientRect('green', 'yellow', 'red', canvas.width, 50);

            // Arrow for consumption and consumption
            drawTriangle('black', this.consumption * (canvas.width / 12000), 38, 20, 20, true);
            drawText('FÖRBRUKNING', 0, 12)

            // Arrow for consumption and price
            drawTriangle('black', this.maxPrice() * (canvas.width / 4), 71, 20, 20, false);
            drawText('PRIS', 0, 100)

        },
    },
};
</script>
  