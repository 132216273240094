<template>
  <div class="container-fluid">
    <div class="row mb-3 bg-primary py-2">
      <div class="col text-end">
        <h1 class="my-2 pt-2">{{ formattedDate }}</h1>
      </div>
      <div class="col pt-2">
        <div id="top-icons" class="row">
          <div class="col">
            <vue-date-picker v-model="selectedDate" class="date-picker" />
          </div>
          <div class="col">
            <img id="refresh" :class="{ 'rotateInfinite': isRotating }" @click="relodeElectricityData"
              src="../Assets/refresh.svg" alt="Reload symbol">
          </div>
        </div>
      </div>
      <div class="col">
        <div class="text-white">Priserna inkluderar:</div>
        <div class="montserrat">Påslag elleverantör: {{ extraCostSek }} kr/Kwh</div>
        <div class="montserrat">Moms: {{ (tax - 1) * 100 }} %</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h2>Denna dag/KWh</h2>
        <table id="todayTable">
          <tr>
            <th class="px-2 py-1">
              <h6>Tid</h6>
            </th>
            <th class="px-2 py-1">
              <h6>Kr</h6>
            </th>
            <th class="px-2 py-1">
              <h6>Förbr.</h6>
            </th>
            <th class="px-2 py-1">
              <h6>Kostn.</h6>
            </th>
          </tr>
          <tr v-for='(data, index) in nordpoolDataAndConsumption' :key='index'
            :class="[data.isActive ? 'active' : '', 'custom-class']" ref="thisDayElements">
            <td class="px-2 py-1">{{ data.startTime.slice(11, 13) }}-{{ data.endTime.slice(11, 13) }} </td>
            <td class="px-2 py-1">{{ ((data.value / 1000 + this.extraCostSek) * this.tax).toFixed(2) }}</td>
            <td v-if='data.isActive' class="px-2 py-1">{{ (activeWattData / 1000).toFixed(3) }}</td>
            <td v-else class="px-2 py-1">{{ (data.watt / 1000).toFixed(3) }}</td>
            <td v-if='data.isActive' class="px-2 py-1">{{ (((data.value / 1000 + this.extraCostSek) * this.tax) *
              (activeWattData / 1000)).toFixed(1) }}</td>
            <td v-else class="px-2 py-1">{{ (((data.value / 1000 + this.extraCostSek) * this.tax) * (data.watt /
              1000)).toFixed(2) }}</td>
          </tr>
        </table>
      </div>
      <div class="col">
        <h2 class="text-nowrap">Nästa dag/KWh</h2>
        <table>
          <tr>
            <th class="px-2 py-1">
              <h6>Tid</h6>
            </th>
            <th class="px-2 py-1">
              <h6>Kr</h6>
            </th>
          </tr>
          <tr v-for='(data, index) in nextDayNordpoolData[0]' :key='index' class="bg-light">
            <td class="px-2 py-1">{{ data.startTime.slice(11, 13) }}-{{ data.endTime.slice(11, 13) }}</td>
            <td class="px-2 py-1">{{ ((data.value / 1000 + this.extraCostSek) * this.tax).toFixed(2) }}</td>
          </tr>
        </table>
      </div>
      <div class="col">
        <div id="watt">
          <h2>Förbrukning</h2>
          <div class="info-container my-2">
            <h5 class="mx-2">Just nu</h5>
            <div id="just-now" class="value-container bg-primary bg-opacity-10">
              <h1 v-if='!wattJustNow' class="my-auto">0 W</h1>
              <h1 v-else class="my-auto">{{ wattJustNow.toFixed(0) }} W</h1>
            </div>
          </div>
          <div class="info-container my-2">
            <h5 class="mx-2">Totalt denna dag</h5>
            <div id="just-now" class="value-container bg-primary bg-opacity-25">
              <h1 v-if='!wattTotalToday' class="my-auto">0 W</h1>
              <h1 v-else class="my-auto">{{ (((wattTotalToday) + (!sameDay ? 0 : activeWattData)) / 1000).toFixed(2) }}
                KWh</h1>
            </div>
          </div>
          <div class="info-container my-2">
            <h5 class="mx-2">Totalt {{ thisMonthName }}</h5>
            <div id="just-now" class="value-container bg-primary bg-opacity-50">
              <h1 v-if='!wattTotalMonth' class="my-auto">0 W</h1>
              <h1 v-else class="my-auto">{{ (wattTotalMonth / 1000).toFixed(1) }} KWh</h1>
            </div>
          </div>
          <div class="info-container my-2">
            <h5 class="mx-2">Totalt {{ thisYear }}</h5>
            <div id="just-now" class="value-container bg-primary bg-opacity-75">
              <h1 class="my-auto">{{ (wattTotalYear / 1000).toFixed(0) }} KWh</h1>
            </div>
          </div>
        </div>
        <div id="kronor">
          <h1>Kostnad</h1>
          <div class="info-container my-2">
            <h5 class="mx-2">Just nu</h5>
            <div id="just-now" class="value-container bg-warning bg-opacity-10">
              <h1 v-if='costJustNow' class="my-auto">{{ ((costJustNow[0].value / 1000 + this.extraCostSek) *
                this.tax).toFixed(2) }} Kr/KWh</h1>
              <h1 v-else class="my-auto">0 Kr</h1>
            </div>
          </div>
          <div class="info-container my-2">
            <h5 class="mx-2">Totalt denna dag</h5>
            <div id="just-now" class="value-container bg-warning bg-opacity-25">
              <h1 v-if='costSelectedDay && costJustNow' class="my-auto">{{ ((costSelectedDay) + (!sameDay ? 0 :
                activeWattData / 1000) *
                ((costJustNow[0].value / 1000 + this.extraCostSek) * this.tax)).toFixed(2) }} Kr</h1>
              <h1 v-else class="my-auto">0 Kr</h1>
            </div>
          </div>
          <div class="info-container my-2">
            <h5 class="mx-2">Totalt {{ thisMonthName }}</h5>
            <div id="just-now" class="value-container bg-warning bg-opacity-50">
              <h1 class="my-auto">{{ ((costMonth) + (!sameMonth ? 0 : costPresentDay)).toFixed(1) }} Kr</h1>
            </div>
          </div>
          <div class="info-container my-2">
            <h5 class="mx-2">Totalt {{ thisYear }}</h5>
            <div id="just-now" class="value-container bg-warning bg-opacity-75">
              <h1 class="my-auto">{{ ((costYear) + (!sameMonth ? 0 : costPresentDay)).toFixed(1) }} Kr</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-2">
      <electricity-graphic :price="costJustNow ? ((costJustNow[0].value / 1000 + this.extraCostSek) * this.tax) : 0" :consumption="wattJustNow" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref, computed } from 'vue';
import { GetEmetersShelly3em } from '../Model/api-requests';
import { dataObject } from '../Model/create-and-refactor-data-object';
import { GetActiveHourWattData } from '../Model/update-watt-on-active-row';
import { getWattForDayMonthOrYear } from '../Model/get-watt-for-day-month-year';
import { GetCostJustNow } from '../Model/get-cost-just-now';
import { GetCostToday } from '../Model/get-cost-today';
import { GetCostMonth } from '../Model/get-cost-month';
import { GetCostYear } from '../Model/get-cost-year';
import { GetNordpoolData } from '../Model/api-requests';
import { GetDateTimeNow } from '../Helpers/getDateTimeNow';
import { GetISOStringSwedenTime } from '../Helpers/getISOStringSwedenTime';
import _settings from '../Settings/cost-settings';
import ElectricityGraphic from './price-and-cunsumption-graphic.vue';
var moment = require('moment-timezone');

export default {
  name: 'StartPage',
  components: {
    VueDatePicker,
    ElectricityGraphic
  },
  setup() {
    const selectedDate = ref(new Date());

    const resetDatePicker = () => {
      selectedDate.value = new Date();
    };

    const formattedDate = computed(() => {
      const day = selectedDate.value.getDate().toString().padStart(2, '0');
      const month = (selectedDate.value.getMonth() + 1).toString().padStart(2, '0');
      const year = selectedDate.value.getFullYear();
      return `${year}-${month}-${day}`;
    });

    const formattedNextDayDate = computed(() => {
      const nextDay = new Date(selectedDate.value);
      nextDay.setDate(nextDay.getDate() + 1);
      const day = nextDay.getDate().toString().padStart(2, '0');
      const month = (nextDay.getMonth() + 1).toString().padStart(2, '0');
      const year = nextDay.getFullYear();
      return `${year}-${month}-${day}`;
    });

    return {
      selectedDate,
      formattedDate,
      formattedNextDayDate,
      resetDatePicker,
    };
  },
  data() {
    return {
      nordpoolDataAndConsumption: {},
      nextDayNordpoolData: {},
      activeWattData: null,
      delayMinuteMs: null,
      delayHourMs: null,
      sameMonth: true,
      sameDay: true,
      wattJustNow: null,
      wattTotalToday: null,
      wattTotalMonth: null,
      wattTotalYear: null,
      costJustNow: null,
      costSelectedDay: null,
      costPresentDay: null,
      costMonth: 0,
      costYear: 0,
      thisMonthName: null,
      thisYear: this.selectedDate.getFullYear(),
      extraCostSek: _settings.extraCostSek,
      tax: _settings.taxPercent / 100 + 1,
      rotateIsActive: false,
      isRotating: false,
    };
  },
  updated() {
    this.$nextTick(() => {
      const thisDayElements = this.$refs.thisDayElements;
      if (thisDayElements) {
        //this.rotateIsActive = false;
      }
    });
  },
  async mounted() {
    this.reloadAfterSleep();
    this.delayMinuteMs = this.createMinuteDelayMS();
    this.delayHourMs = this.createHourDelayMS();
    this.getMonthName();
    this.startIntervals();
    await this.setWattJustNow();
    this.nordpoolDataAndConsumption = await dataObject(this.formattedDate);
    this.nextDayNordpoolData = await GetNordpoolData(this.formattedNextDayDate);
    this.wattTotalYear = await getWattForDayMonthOrYear(this.formattedDate, 'year');
    this.wattTotalMonth = await getWattForDayMonthOrYear(this.formattedDate, 'month');
    this.wattTotalToday = await getWattForDayMonthOrYear(this.formattedDate, 'day');
    this.costJustNow = await GetCostJustNow();
    this.costSelectedDay = GetCostToday(this.nordpoolDataAndConsumption);
    this.costPresentDay = GetCostToday(this.nordpoolDataAndConsumption);
    this.costMonth = await GetCostMonth(this.formattedDate);
    this.costYear = await GetCostYear(this.formattedDate);
    this.sameMonth = this.comparePresentDayAndSelectedDay("month");
    this.sameDay = this.comparePresentDayAndSelectedDay("day");

    //console.log('nordpoolDataAndConsumption:', this.nordpoolDataAndConsumption);
    //console.log('nextDayNordpoolData:', this.nextDayNordpoolData);

    //*** Used watt just now */
    this.nordpoolDataAndConsumption.forEach(async (hour, index) => {
      if (hour.isActive) {
        this.activeWattData = await GetActiveHourWattData(hour.startTime);
      }
    });

  },
  methods: {
    reloadAfterSleep() {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          this.updateData();
        }
      });
    },
    clearDataBeforeUpdate() {
      this.nordpoolDataAndConsumption = [];
      this.nextDayNordpoolData = [];
      this.wattTotalMonth = 0;
      this.wattTotalToday = 0;
      this.costSelectedDay = 0;
      this.costPresentDay = 0;
      this.costMonth = 0;
    },
    getMonthName() {
      this.thisMonthName = this.selectedDate.toLocaleString('sv-SE', { month: 'long' })
    },
    startRotateLoadingIcon() {
      this.rotateIsActive = true;
      this.isRotating = true;
      let interval = setInterval(() => {
        if (!this.rotateIsActive) {
          this.isRotating = false;
          clearInterval(interval);
        }
      }, 2000);
    },
    async relodeElectricityData() {
      this.resetDatePicker();
      this.updateData();
    },
    createMinuteDelayMS() {
      const now = new Date();
      return 60000 - now.getSeconds() * 1000 - now.getMilliseconds();
    },
    createHourDelayMS() {
      const now = new Date();
      const nextHour = new Date(now);
      nextHour.setHours(now.getHours() + 1);
      nextHour.setMinutes(0);
      nextHour.setSeconds(0);
      nextHour.setMilliseconds(0);
      return nextHour.getTime() - now.getTime() + 10000;
    },
    async setWattJustNow() {
      let wattJustNow = 0;
      const shelly3emEmeters = await GetEmetersShelly3em();
      if (shelly3emEmeters[0]) {
        shelly3emEmeters[0].forEach((emeter) => {
          wattJustNow += (emeter.current * emeter.voltage);
          this.wattJustNow = wattJustNow;
        });
      }
    },
    async startIntervals() {
      //** Schemalägg anropen var annan sekund */
      setInterval(async () => {
        //*** Used watt just now */
        await this.setWattJustNow()
      }, 2000);

      //** Schemalägg anropen varje minut */
      setTimeout(() => {
        setInterval(async () => {
          const dateTime = moment().tz("Europe/Stockholm").format();
          //console.log('VARJE MINUT!', dateTime);

          // Update minute TEST
          //this.nordpoolDataAndConsumption = await dataObject(this.formattedDate);

          //** Updating watt used for this hour */
          this.nordpoolDataAndConsumption.forEach(async (hour, index) => {
            if (hour.isActive) {
              this.activeWattData = await GetActiveHourWattData(hour.startTime);
            }
          });

          //** Watt used totally this month */
          this.wattTotalMonth = await getWattForDayMonthOrYear(this.formattedDate, 'month');

        }, 60000);
      }, this.delayMinuteMs);

      //** Schemalägg anropen varje timme */
      setTimeout(() => {
        setInterval(async () => {
          const dateTime = moment().tz("Europe/Stockholm").format();
          //console.log('VARJE TIMME!', dateTime);

          //** Updating alla data for refresh */
          this.updateData();

        }, 3600000);
      }, this.delayHourMs);
    },
    comparePresentDayAndSelectedDay(interval) {
      if (interval === "month") {
        let today = GetDateTimeNow();
        let selectedDay = new Date(this.formattedDate)
        return today.getMonth() === selectedDay.getMonth() && today.getFullYear() === selectedDay.getFullYear();
      }
      if (interval === "day") {
        let today = GetDateTimeNow();
        let todayISO = GetISOStringSwedenTime(today).split("T")[0].replace(":", "-");
        return todayISO === this.formattedDate;
      }
    },
    async updateData() {
      this.clearDataBeforeUpdate();
      this.getMonthName();
      this.startRotateLoadingIcon();
      //*** Update consumption data */
      this.nordpoolDataAndConsumption = await dataObject(this.formattedDate);

      //*** Update watt used this hour */
      this.nordpoolDataAndConsumption.forEach(async (hour, index) => {
        if (hour.isActive) {
          this.activeWattData = await GetActiveHourWattData(hour.startTime);
        }
      });

      //*** Update watt used totally today */
      this.wattTotalToday = await getWattForDayMonthOrYear(this.formattedDate, 'day');

      //*** Update watt used this month */
      this.wattTotalMonth = await getWattForDayMonthOrYear(this.formattedDate, 'month');

      //*** Update cost for selected day */
      this.costSelectedDay = GetCostToday(this.nordpoolDataAndConsumption);

      //*** Update cost totally for month */
      this.costMonth = await GetCostMonth(this.formattedDate);

      //** Updating the kWh-price for this hour */
      this.costJustNow = await GetCostJustNow();

      //*** Compare selected date with present date to se if they are in the same month */
      this.sameMonth = this.comparePresentDayAndSelectedDay("month");

      //*** Compare selected date with present date to se if they are in the same month */
      this.sameDay = this.comparePresentDayAndSelectedDay("day");

      //*** Update next day NoordPool data*/
      this.nextDayNordpoolData = await GetNordpoolData(this.formattedNextDayDate);

      //*** Stops rotating update button */
      this.rotateIsActive = false;
    },
  },
  computed: {

  },
  watch: {
    formattedDate: function () {
      this.updateData();
    },
    nordpoolDataAndConsumption: function () {
      //Get the costs for selected day
      this.costSelectedDay = GetCostToday(this.nordpoolDataAndConsumption);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@keyframes rotateRefresh {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#todayTable {
  white-space: nowrap;
}

#top-icons {
  align-items: center;
  min-width: 8rem !important;
}

#refresh {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

.rotateInfinite {
  animation: rotateRefresh 2s linear infinite;
}

.date-picker {
  width: 0;
}

.date-picker>>>input {
  display: none;
}

.date-picker>>>.dp__input_icons {
  width: 2.5rem !important;
  height: 2.5rem !important;
  fill: black;
  top: 55%;
}

.date-picker>>>.dp__clear_icon {
  display: none;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

h5 {
  padding: 0;
  margin: 0;
}

.value-container {
  width: 100%;
  padding: .4rem;
  border-radius: 5px;
}

#kronor {
  margin-top: 1rem;
}

td {
  font-family: 'Montserrat', sans-serif;
  font-size: 10.7pt;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
  font-size: small;
  color: white;
}

.active {
  background-color: black !important;
  color: white;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.4);
}
</style>
