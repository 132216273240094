/* eslint-disable */
import { GetNordpoolData, GetUsedWattFromAllEmetersBetweenDates } from './api-requests';
import { mockNordpoolData } from './mockNordpoolData';
import { GetISOStringSwedenTime } from '../Helpers/getISOStringSwedenTime';
import { GetDateTimeNow } from '@/Helpers/getDateTimeNow';

export const dataObject = async (dateTime) => {
    let data = null;
    const nowTime = GetISOStringSwedenTime(GetDateTimeNow());
    //console.log('nowTime:',nowTime );
    data = await GetNordpoolData(dateTime);
    data = data[0];
    //console.log('data[0]', data);
    const randomNum = Math.floor(Math.random() * 24) + 1;
    for (let i = 0; i < data.length; i++) {

        if (nowTime>data[i].startTime && nowTime<data[i].endTime) {
            data[i].isActive = true; 
        } else {
            data[i].isActive = false;
        }

        //*** bara test! */
        
        // if (i === randomNum) {
        //     data[i].isActive = true; 
        // } else {
        //     data[i].isActive = false;
        // }

        // Chahes the time to sweden time in ISOString format
        const endTime = GetISOStringSwedenTime(data[i].endTime, 10);
        let usedWatt = await GetUsedWattFromAllEmetersBetweenDates(`${data[i].startTime}.000Z`, endTime);

        if (!usedWatt[0]){
            data[i].watt = 0;
        }
        // else if (JSON.stringify(usedWatt[0]) !== JSON.stringify({ low: 0, high: 0 })) {
        else if (typeof usedWatt[0] === 'object') {
            data[i].watt = usedWatt[0].low;
        }
        else if (typeof usedWatt[0] === 'number') {
            data[i].watt = usedWatt[0];
        }
        else {
            data[i].watt = 0;
        }
    };
    //console.log('data outside', data);
    return data;
};

