export const GetISOStringSwedenTime = (date, offsetMinutes = 0, offsetHours = 0) => {

    // Creates a new date object from parameter
    let newDate = new Date(date);

    // If offset, minutes or offsetHours is set, add the offset to the newDate
    newDate.setMinutes(newDate.getMinutes() + offsetMinutes);
    newDate.setHours(newDate.getHours() + offsetHours);

    // Get the offset in milliseconds
    let offset = newDate.getTimezoneOffset()*60000;

    // Return the new date in ISOString format minus the offset in milliseconds
    return new Date(newDate.getTime() - offset).toISOString();
}

