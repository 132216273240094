import _settings from '../Settings/cost-settings';

export const GetCostToday = (array) => {
//console.log('array: ', array);
let totalCostToday = 0;
array.forEach((hour) => {
    let sum = (((hour.value/1000 + _settings.extraCostSek) * (_settings.taxPercent/100+1)) * (hour.watt/1000));
    totalCostToday += sum;
});
return totalCostToday;
}