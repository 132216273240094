<template>
  <StartPage/>
</template>

<script>
import StartPage from './Components/start-page.vue';

export default {
  name: 'App',
  components: {
    StartPage
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');
#app {
  font-family: Bakbak One, Avenir, Helvetica, Arial, sans-serif;
}
</style>
