//const apiUrl = 'http://localhost:3000';
const apiUrl = 'http://192.168.1.3:3030';

export const GetNordpoolData = async (dateTime) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${apiUrl}/nordpool?dateTime=${dateTime}`, requestOptions);
        let data = await response.json();
        return [data, null]
    } catch (err) {
        return [null, err]
    }
}

export const GetUsedWattFromAllEmetersBetweenDates = async (startDateTime, endDateTime) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${apiUrl}/emeters/totalWatt?startDateTime=${startDateTime}&endDateTime=${endDateTime}`, requestOptions);
        let data = await response.json();
        return [data, null]
    } catch (err) {
        return [null, err]
    }
}

export const GetTotalWattForAnHourFromAllEmeters = async (dateTime) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${apiUrl}/emeters/watt?dateTime=${dateTime}`, requestOptions);
        let data = await response.json();
        return [data, null]
    } catch (err) {
        return [null, err]
    }
}

export const GetTotalCostForDaysBetweenDates = async (startTime, endTime) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${apiUrl}/cost/day?startTime=${startTime}&endTime=${endTime}`, requestOptions);
        let data = await response.json();
        return [data, null]
    } catch (err) {
        return [null, err]
    }
}

export const GetEmetersShelly3em = async () => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${apiUrl}/shelly3em/emeters`, requestOptions);
        let data = await response.json();
        return [data, null]
    } catch (err) {
        return [null, err]
    }
}