import { GetTotalCostForDaysBetweenDates } from '../Model/api-requests';
import { GetMonthYearSearchSpan } from '../Helpers/getMonthYearSearchSpan';

export const GetCostMonth = async (todayDate) => {

    const dates = await GetMonthYearSearchSpan(todayDate, 'month');
    const cost = await GetTotalCostForDaysBetweenDates(dates[0], dates[1]);
    if(cost[0]) {
        if (JSON.stringify(cost[0]) !== JSON.stringify({ low: 0, high: 0 })) {
            return cost[0];
        } else {
            return cost[0].low
        }
    } else {
        console.log('err:', cost[1]);
        return 0;
    }
}